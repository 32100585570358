import React from 'react'

import {
    EmailShareButton, EmailIcon,
    LinkedinShareButton, LinkedinIcon,
    TwitterShareButton, TwitterIcon,
    FacebookShareButton, FacebookIcon,
    WhatsappShareButton, WhatsappIcon,
    RedditShareButton, RedditIcon,
  } from "react-share";
  
  const ShareIcons = ({data}) => {
    const size = 32, url = 'https://brillea.com/blog/' + data;
    return (
      <div className="share_icons">
        <hr/>
        <h4>Share:</h4> 
        <div className="share_icons__list">
  
          <div className="share_icons__list_button">
            <EmailShareButton url={url}>
              <EmailIcon size={size} round />
            </EmailShareButton>
          </div>
  
          <div className="share_icons__list_button">
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={size} round/>
          </LinkedinShareButton>
          </div>
          <div className="share_icons__list_button">
          <TwitterShareButton url={url}>
            <TwitterIcon size={size} round/>
          </TwitterShareButton>
          </div>
          <div className="share_icons__list_button">
          <FacebookShareButton url={url}>
            <FacebookIcon size={size} round/>
          </FacebookShareButton>
          </div>
          <div className="share_icons__list_button">
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={size} round/>
          </WhatsappShareButton>
          </div>
          <div className="share_icons__list_button">
          <RedditShareButton url={url}>
            <RedditIcon size={size} round/>
          </RedditShareButton>
          </div>
        </div>
      </div>
    )
  }

export default ShareIcons