import React from 'react'
import { Link } from 'gatsby'

const PostLinks = props => {
  const NextPath = props?.basePath + '/' + props?.next?.slug + '/';
  const PrevPath = props?.basePath + '/' + props?.previous?.slug + '/';
  return (
      <div className="PostLinks">
        {props.previous && (
        <Link className="PostLinks_PreviousLink" to={PrevPath}>
            <span className="icon">&#8592;</span> Prev
        </Link>
        )}
        <Link className="PostLinks_AllArticles" to={props.basePath}>
            All articles
        </Link>
        {props.next && (
        <Link className="PostLinks_NextLink" to={NextPath}>
            Next <span className="icon">&#8594;</span>
        </Link>
        )}
      </div>
  )
}

export default PostLinks
