import React from "react"
import { graphql } from "gatsby"

import PostLinks from "../components/Blog/PostLinks"
import ShareIcons from "../components/Blog/ShareIcons"
// import Tags from "../components/Blog/Tags"
import ReactMarkdown from "react-markdown"
import Image from "gatsby-image"
// import { Link } from "gatsby"

import SEO from "../components/SEO"
import FooterEmailSignup from "../components/Footer/FooterEmailSigngup"

// const slugify = require("slugify")

const BlogTemplate = ({ data, pageContext }) => {
  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext

  const {
    title,
    seoMetaTitle,
    seoMetaDescription,
    category,
    slug,
    body: { body },
    heroImage,
  } = data.post

  const seoTitle = seoMetaTitle ? seoMetaTitle : title
  const seoDescription = seoMetaDescription ? seoMetaDescription : null

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname={basePath + "/" + slug}
      />
      <article className="post-full post grey_bk">
        
          {heroImage ? (
            <div className="blogCard post-full-header">
              <div className="card__img">
                {heroImage ? (
                  <Image fluid={heroImage.fluid} style={{ height: "100%" }} />
                ) : null}
              </div>
              <div className="card__info">
                <span className="card__category">{category}</span>
                <h2 className="h1 card__title">{title}</h2>
              </div>
            </div>
          ) : (
            <div className="blogCard blogCard_text post-full-header">
              <div className="card__info card__info_full">
                <span className="card__category">{category}</span>
                <h2 className="h1">{title}</h2>
              </div>
          </div>
          )}

        <section className="post-full-content-wrapper">
          <section className="post-full-content">
            <ReactMarkdown source={body}/>
            <ShareIcons data={slug} />
          </section>
        </section>

        <PostLinks previous={previous} next={next} basePath={basePath} />
      </article>
      <FooterEmailSignup />
    </>
  )
}

export const getPost = graphql`
  query getPost($id: String!) {
    post: contentfulBlogPost(contentful_id: { eq: $id }) {
      title
      category
      seoMetaTitle
      seoMetaDescription
      publishDate
      slug
      body {
        body
      }
      heroImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default BlogTemplate
